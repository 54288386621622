import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";
console.log("hello");

const applyTooltip = (target, content) => {
  tippy(target, {
    content,
    arrow: false,
    placement: "bottom",
    theme: "oshiruko",
  });
};

applyTooltip(".youtube-link", "YouTube");
applyTooltip(".blog-link", "Blog");
applyTooltip(".x-link", "X (旧 Twitter)");
applyTooltip(".booth-link", "Booth");
applyTooltip(".github-link", "GitHub");

fetch(
  "https://bzl6rx23pqxcxzapingbje2seq0ybzxl.lambda-url.ap-northeast-1.on.aws/",
  {
    mode: "cors",
    headers: {
      "Content-Type": "text/plain",
    },
  }
)
  .then((response) => response.json())
  .then((data) => {
    const { last_hr, last_hr_ms } = data;
    const heart_ms = (60 * 1000) / last_hr;
    const last_hr_minutes = Math.floor((Date.now() - last_hr_ms) / (60 * 1000));
    if (last_hr_minutes <= 60 * 24) {
      applyTooltip(
        ".heart-link",
        `心拍数: ${last_hr} bpm (${last_hr_minutes} 分前)`
      );

      const e = document.querySelector(".heart-link");
      e.classList.add("heart-visible");
      document.documentElement.style.setProperty("--heart-ms", `${heart_ms}ms`);
    } else {
      applyTooltip(".heart-link", `心拍数: 24時間以内のデータがありません`);
    }
  });

applyTooltip(
  ".message input",
  "おしるこテクノロジーの Discord に匿名でメッセージを送信できます。Emoji 以外の文字は取り除かれます 🤗"
);

const button = document.querySelector(".message button");
button.onclick = () => {
  const input = document.querySelector(".message input");
  const content = input.value;
  if (content !== "") {
    fetch(
      "https://script.google.com/macros/s/AKfycbw6LkDtlD5TuoMSb9v1jG48VIq6orpMCZSVWOR5bqsT4vUUJ4YIZNGdRZQ3HaunrwAcvw/exec",
      {
        method: "POST",
        mode: "cors",
        body: `message=${encodeURIComponent(content)}`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    )
      .then((data) => {
        input.value = "";
        const sb = document.querySelector(".snackbar.success");
        sb.classList.add("show");
        setTimeout(() => {
          sb.classList.remove("show");
        }, 5000);
      })
      .catch((err) => {
        const sb = document.querySelector(".snackbar.failed");
        sb.classList.add("show");
        setTimeout(() => {
          sb.classList.remove("show");
        }, 5000);
      });
  }
};
